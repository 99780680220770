<template>
  <div>
    <el-card>
      <h1>总数统计</h1>
      <div class="content flex-align">
        <item
          :detail="item"
          :Total="Total"
          class="box-item"
          v-for="(item, index) in list"
          :key="index"
        ></item>
      </div>
      <div style="height:50px"></div>
      <h1>按时间周期统计</h1>
      <nav class="search">
        <el-date-picker
          v-model="time"
          type="daterange"
          :picker-options="pickerOptions"
          range-separator="至"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
        <el-button class="btn" type="primary" size="small" @click="submit"
          >搜索</el-button
        >
      </nav>
      <template v-for="(item, index) in infoList">
        <h2>{{ item.name }}</h2>
        <div class="content flex-align">
          <item
            :detail="i"
            :Total="detail"
            class="box-item"
            v-for="(i, v) in item.list"
            :key="v"
          ></item>
        </div>
      </template>

      <!-- <h2>用户模块</h2>
      <div class="content flex-align">
        <item
          :detail="item"
          :Total="detail"
          class="box-item"
          v-for="(item, index) in userList"
          :key="index"
        ></item>
      </div>
      <h2>信息模块</h2>
      <div class="content flex-align">
        <item
          :detail="item"
          :Total="detail"
          class="box-item"
          v-for="(item, index) in infoList"
          :key="index"
        ></item>
      </div> -->
    </el-card>
  </div>
</template>

<script>
import item from './item/index'
import moment from 'moment'
// import vue from 'vue'
// import ws from '../../../plugin/publicMethod'
// var throttle = ws.throttle
export default {
  components: {
    item
  },
  data() {
    let self = this
    return {
      list: [
        {
          key: 'userRegisterTotal',
          name: '注册用户总数',
          unit: '人'
        },
        {
          key: 'userCertTotal',
          key2: 'userCertRate',
          name: '认证用户总数',
          unit: '人',
          name2: '认证率',
          unit2: '%'
        },
        {
          key: 'userCompleteInfoTotal',
          name: '完善信息用户总数',
          unit: '人',
          key2: 'userCompleteInfoRate',
          name2: '完善率',
          unit2: '%'
        },
        {
          key: 'articleTotal',
          name: '资讯总数',
          unit: '篇'
          //   key2: 'userCompleteInfoRate',
          //   name2: '完善率',
          //   unit2: '%'
        },
        {
          key: 'newsTotal',
          name: '快讯总数',
          unit: '篇'
        },
        {
          key: 'activityTotal',
          name: '活动总数',
          unit: '个'
        },
        {
          key: 'reportTotal',
          name: '报告总数',
          unit: '份'
        },
        {
          key: 'orderMoneyTotal',
          name: '订单总额',
          unit: '元'
        },
        {
          key: 'cooperationTotal',
          name: '合作总数',
          unit: '条',
          key3: 'cooperationOwnerTotal',
          name3: '甲方',
          unit3: '条',
          key4: 'cooperationSupplierTotal',
          name4: '乙方',
          unit4: '条'
        }
      ],
      userList: [
        {
          key: 'userRegister',
          name: '注册用户数',
          unit: '人'
        },
        {
          key: 'userCert',
          name: '认证用户数',
          unit: '人'
        },
        {
          key: 'userCompleteInfo',
          name: '完善信息用户',
          unit: '人'
        }
      ],
      infoList: [
        {
          name: '用户模块',
          list: [
            {
              key: 'userRegister',
              name: '注册用户数',
              unit: '人'
            },
            {
              key: 'userCert',
              name: '认证用户数',
              unit: '人',
            //   key2: 'userCertRate',
            //   name2: '认证率',
            //   unit2: '%',
              tip:
                '当天通过认证的用户数'
            },
            {
              key: 'userCompleteInfo',
              name: '完善信息用户',
              unit: '人',
            //   key2: 'userCompleteInfoRate',
            //   name2: '完善率',
            //   unit2: '%',
              tip:
                '当天完善信息的用户数'
            }
          ]
        },
        {
          name: '信息内容模块',
          list: [
            {
              key: 'articleAdd',
              name: '资讯新增数量',
              unit: '篇'
            },
            {
              key: 'newsAdd',
              name: '快讯新增数量',
              unit: '篇'
            },
            {
              key: 'reportAdd',
              name: '报告新增数量',
              unit: '份'
            },
            {
              key: 'activityAdd',
              name: '活动新增数量',
              unit: '个'
            }
          ]
        },
        // {
        //   name: '合作模块',
        //   list: [
        //     {
        //       key: 'cooperationTotal',
        //       name: '合作总数',
        //       unit: '条',
        //       key3: 'cooperationOwner',
        //       name3: '甲方',
        //       unit3: '条',
        //       key4: 'cooperationSupplier',
        //       name4: '乙方',
        //       unit4: '条'
        //     },
        //     {
        //       key: 'cooperationApply',
        //       name: '合作申请数量',
        //       unit: '条'
        //     },
        //     {
        //       key: 'cooperationRead',
        //       name: '合作查看数量',
        //       unit: '条'
        //     }
        //   ]
        // },
        {
          name: '合作模块',
          list: [
            {
              key: 'cooperationAll',
              name: '合作数量',
              unit: '条',
              key3: 'cooperationOwner',
              name3: '甲方',
              unit3: '条',
              key4: 'cooperationSupplier',
              name4: '乙方',
              unit4: '条'
            },
            {
              key: 'cooperationApply',
              name: '合作申请数量',
              unit: '条',
              key2: 'cooperationRead',
              name2: '合作查看数量',
              unit2: '条'
            }
          ]
        },
        {
          name: '圈子模块',
          list: [
            {
              key: 'businessCardSend',
              name: '名片发送次数',
              unit: '条',
              key2: 'businessCardRead',
              name2: '已读',
              unit2: '条'
            },
            {
              key: 'contactsView',
              name: '联系方式查看次数',
              unit: '条'
            },
            {
              key: 'messageSend',
              name: '私信消息发送条数',
              unit: '条',
              key2: 'messageRead',
              name2: '已读',
              unit2: '条'
            }
          ]
        },
        {
          name: '订单模块',
          list: [
            // {
            //   key: 'orderWaitPay',
            //   name: '待支付笔数',
            //   unit: '笔'
            // },
            {
              key: 'orderWaitPay',
              name: '待支付笔数',
              unit: '笔',
              key2: 'orderWaitPayUser',
              name2: '订单待支付人数',
              unit2: '人'
            },
            // {
            //   key: 'orderPay',
            //   name: '已支付笔数',
            //   unit: '笔'
            // },
            {
              key: 'orderPay',
              name: '订单支付笔数',
              unit: '笔',
              key2: 'orderPayUser',
              name2: '订单支付人数',
              unit2: '人'
            },
            {
              key: 'orderPayMoney',
              name: '已支付总金额',
              unit: '元'
            }
          ]
        },
        {
          name: '内币模块',
          list: [
            {
              key: 'bonusTimesAll',
              name: '积分行为条数',
              unit: '条',
              key3: 'bonusAddTimes',
              name3: '增加条数',
              unit3: '条',
              key4: 'bonusConsumeTimes',
              name4: '消耗条数',
              unit4: '条'
            },
            {
              key: 'bonusAddTotal',
              name: '产生积分总额'
            },
            {
              key: 'bonusConsumeTotal',
              name: '消耗积分总额'
            },
            {
              key: 'coinAddTotal',
              name: '充值帆币总额'
            },
            {
              key: 'coinConsumeTotal',
              name: '消耗帆币总额'
            }
          ]
        }
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
              self.getlist()
            }
          },
          {
            text: '上上周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 14)
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
              self.getlist()
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
              self.getlist()
            }
          },
          {
            text: '上上个月',
            onClick(picker) {
              const end = moment()
                .endOf('month')
                .subtract('month', 2)
                .valueOf()
              const start = moment()
                .startOf('month')
                .subtract('month', 2)
                .valueOf()
              picker.$emit('pick', [new Date(start), new Date(end)])
              self.getlist()
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
              self.getlist()
            }
          }
        ]
      },
      detail: {},
      search: {
        time: '2022-06-08~2022-07-01'
      },
      time: [
        moment()
          .subtract(1, 'day')
          .format('YYYY-MM-DD'),
        moment()
          .subtract(1, 'day')
          .format('YYYY-MM-DD')
      ],
      Total: {}
    }
  },
  created() {
    this.getlist()
    this.getTol()
  },
  watch: {
    time(newValue, oldValue) {
      console.log(newValue)
    }
  },
  methods: {
    // console.log(this);
    async getlist() {
      const { data } = await this.$http.get('/admin/Statistics/getList', {
        params: {
          time: this.time.join('~')
        }
      })
      this.detail = data.data
    },

    submit() {
      this.getlist()
    },
    async getTol() {
      const { data } = await this.$http.get('/admin/Statistics/getTotal')
      //   console.log(data)
      this.Total = data.data
    }
  }
}
</script>

<style lang="less" scoped>
.box-item {
  margin-right: 30px;
  margin-bottom: 30px;
}
.content {
  flex-wrap: wrap;
}
h1 {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 24px;
  .clamp(1);
}
h2 {
  margin-bottom: 20px;
  font-weight: bold;
}
.search {
  margin-bottom: 20px;
  .btn {
    margin-left: 30px;
  }
}
</style>
