<template>
  <div class="box flex-center">
    <div class="box-item">
      <div class="title">
        {{ detail.name }}
        <el-tooltip effect="dark" placement="top" v-if="detail.tip">
          <div slot="content">{{ detail.tip }}</div>
          <i class="el-icon-question" style="color:#999"></i>
        </el-tooltip>
      </div>
      <div class="desc">{{ Total[detail.key] || 0 }} {{ detail.unit }}</div>
      <div class="key2" v-if="Total[detail.key2]">
        {{ detail.name2 }}：{{ Total[detail.key2] || 0 }}{{ detail.unit2 }}
      </div>
      <div class="key3" v-if="detail.key3">
        {{ detail.name3 }}：{{ Total[detail.key3] || 0 }}{{ detail.unit3 }}
        <span></span>
        {{ detail.name4 }}：{{ Total[detail.key4] || 0 }}{{ detail.unit4 }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: [Object],
      default: () => {}
    },
    Total: {
      type: [Object],
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  width: auto;
  min-width: 150px;
  height: 120px;
  border: 1px solid #08d;
  padding: 0 30px;
  .box-item {
    text-align: center;
  }
  .title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
  }
  .key2 {
    font-size: 14px;
    margin-top: 10px;
  }
  .key3 {
    font-size: 14px;
    margin-top: 10px;
    span {
      display: inline-block;
      margin: 0 5px;
    }
  }
}
</style>
